//背景用色
$color-green:#01916d; //主题绿色
$color-green-dark:#026d52;
$color-light-blue:#e0e0e0; //浅蓝背景色
$color-white:#fff; //白色

//border用色
$color-line:#b2b2b2;

//文字用色
$color-red:#ff0909; //错误提示文字颜色
$color-title:#666; //标题黑色
$color-text:#333; //正文颜色  80%黑
$color-desc:#666666; //解释性文字颜色  60%黑

//文字大小
$text-size-sm-base:14px;
$text-size-sm-ratio:12 / 14;

$text-size-base:16px; //正文大小
$text-size-ratio:14 / 16; //缩放

$text-size-md-base:18px; //列表头条内容文字大小
$text-size-md-ratio:14 / 18; //缩放

$text-size-lg-base:20px;
$text-size-lg-ratio:16 / 20;

//tag文字大小
$tag-size-base: 16px;
$tag-size-ratio: 14 / 16;

//标题大小，由小到大
$title-size-xs-base:22px;
$title-size-xs-ratio:16 / 22;

$title-size-sm-base:26px;
$title-size-sm-ratio:16 / 26;

$title-size-md-base:30px;
$title-size-md-ratio:18 / 30;

$title-size-lg-base:34px;
$title-size-lg-ratio:18 / 34;

// 动画效果持续时间
$anime-duration: 0.25s;
//贝塞尔曲线
$anime-bezier:cubic-bezier(0.77, 0, 0.175, 1);

//header高度
$header-height-base: 70px;
$header-height-ratio: 70 / 70;

//内容部分左右留白
$container-gutter-base:80px;
$container-gutter-ratio:10 / 80;

//阴影
$shadow-offset: 0px 3px;
$shadow-spread: 10px;
$shadow-color:#dbdada;

$jason-map-width: 400px !default;
$jason-map-duration: .5s !default;
$jason-map-theme: $color-green !default;

//汉堡包按钮参数设置
$hamburger-layer-width: 28px; //汉堡包按钮每一条线的宽度
$hamburger-layer-height: 3px; //汉堡包按钮每一条线的高度
$hamburger-layer-spacing: 5px; //汉堡包按钮每一条线的高度间隔
$hamburger-layer-color: $color-green; //汉堡包按钮线的颜色
$hamburger-layer-border-radius: 0; //汉堡包按钮线的圆角效果

// 响应式断点，需要从小到大写
$layout-responsive-breakpoint: (
  // 手机
  xs: (min-width: 0px, container-width: 100%),
  // 平板
  sm: (min-width: 768px, container-width: 100%),
  // 小屏
  md: (min-width: 992px, container-width: 100%),
  // 中屏
  mmd: (min-width: 1025px, container-width: 100%),
  // 大屏
  lg: (min-width: 1366px, container-width: 100%),
  
  llg: (min-width: 1440px, container-width: 100%),
  
  1lg: (min-width: 1600px, container-width: 100%),
  
  xlg: (min-width: 1681px, container-width: 100%)) !default;
