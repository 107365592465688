.index-banner {
  height: 100vh;
}

@media (max-width: 1024px) {
  .index-banner {
    height: 600px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    height: 500px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    height: 400px;
  }
}

.index-banner .swiper-container {
  height: 100%;
}

.index-banner .swiper-container .swiper-slide {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  z-index: 0;
}

.index-banner .swiper-container .swiper-slide.swiper-slide-active {
  z-index: 1;
}

.index-banner .swiper-container .swiper-slide.swiper-slide-active .banner-text span, .index-banner .swiper-container .swiper-slide.swiper-slide-active .banner-text .line, .index-banner .swiper-container .swiper-slide.swiper-slide-active .banner-text .small {
  top: 0px;
  opacity: 1;
  transition-delay: 1s;
}

.index-banner .swiper-container .swiper-slide .slide-inner {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}

.index-banner .swiper-container .swiper-slide .slide-inner b {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.index-banner .swiper-container .swiper-slide .slide-inner b::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.index-banner .swiper-container .swiper-slide .slide-inner b.pc-img {
  display: block;
}

@media (max-width: 991px) {
  .index-banner .swiper-container .swiper-slide .slide-inner b.pc-img {
    display: none;
  }
}

.index-banner .swiper-container .swiper-slide .slide-inner b.mob-img {
  display: none;
}

@media (max-width: 991px) {
  .index-banner .swiper-container .swiper-slide .slide-inner b.mob-img {
    display: block;
  }
}

.index-banner .swiper-container .swiper-slide .slide-inner .video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.index-banner .swiper-container .swiper-slide .slide-inner .video::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.index-banner .swiper-container .swiper-slide .slide-inner .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.index-banner .swiper-container .swiper-slide .slide-inner .video .video-play {
  position: absolute;
  z-index: 3;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;
  top: 50%;
  display: none;
  z-index: 101;
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-slide .slide-inner .video .video-play {
    top: 58%;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-container .swiper-slide .slide-inner .video .video-play {
    display: block;
  }
}

.index-banner .swiper-container .swiper-slide .slide-inner .video .video-play img {
  display: inline-block;
  width: 60px;
}

.index-banner .swiper-container .swiper-slide .slide-inner .video-play {
  position: absolute;
  z-index: 3;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;
  top: 50%;
  display: none;
  z-index: 101;
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-slide .slide-inner .video-play {
    top: 58%;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-container .swiper-slide .slide-inner .video-play {
    display: block;
  }
}

.index-banner .swiper-container .swiper-slide .slide-inner .video-play img {
  display: inline-block;
  width: 60px;
}

.index-banner .swiper-container .swiper-slide .banner-text {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: 330px;
  padding-left: 330px;
  z-index: 99;
}

@media (max-width: 1680px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    padding-right: 291.42857px;
  }
}

@media (max-width: 1599px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    padding-right: 252.85714px;
  }
}

@media (max-width: 1439px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    padding-right: 214.28571px;
  }
}

@media (max-width: 1365px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    padding-right: 175.71429px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    padding-right: 137.14286px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    padding-right: 98.57143px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    padding-right: 60px;
  }
}

@media (max-width: 1680px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    padding-left: 291.42857px;
  }
}

@media (max-width: 1599px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    padding-left: 252.85714px;
  }
}

@media (max-width: 1439px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    padding-left: 214.28571px;
  }
}

@media (max-width: 1365px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    padding-left: 175.71429px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    padding-left: 137.14286px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    padding-left: 98.57143px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-slide .banner-text {
    padding-left: 60px;
  }
}

.index-banner .swiper-container .swiper-slide .banner-text span {
  font-weight: bold;
  position: relative;
  top: 50px;
  opacity: 0;
  transition: all .8s;
  transition-delay: 0s;
  font-size: 46px;
}

@media (max-width: 1680px) {
  .index-banner .swiper-container .swiper-slide .banner-text span {
    font-size: 42.57143px;
  }
}

@media (max-width: 1599px) {
  .index-banner .swiper-container .swiper-slide .banner-text span {
    font-size: 39.14286px;
  }
}

@media (max-width: 1439px) {
  .index-banner .swiper-container .swiper-slide .banner-text span {
    font-size: 35.71429px;
  }
}

@media (max-width: 1365px) {
  .index-banner .swiper-container .swiper-slide .banner-text span {
    font-size: 32.28571px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-container .swiper-slide .banner-text span {
    font-size: 28.85714px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-container .swiper-slide .banner-text span {
    font-size: 25.42857px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-slide .banner-text span {
    font-size: 22px;
  }
}

.index-banner .swiper-container .swiper-slide .banner-text .line {
  width: 270px;
  height: 2px;
  background: #fff;
  margin-top: 20px;
  position: relative;
  top: 60px;
  opacity: 0;
  transition: all .8s;
  transition-delay: 0s;
}

.index-banner .swiper-container .swiper-slide .banner-text .small {
  font-size: 26px;
  margin-top: 20px;
  position: relative;
  top: 70px;
  opacity: 0;
  transition: all .8s;
  transition-delay: 0s;
}

@media (max-width: 1680px) {
  .index-banner .swiper-container .swiper-slide .banner-text .small {
    font-size: 24.85714px;
  }
}

@media (max-width: 1599px) {
  .index-banner .swiper-container .swiper-slide .banner-text .small {
    font-size: 23.71429px;
  }
}

@media (max-width: 1439px) {
  .index-banner .swiper-container .swiper-slide .banner-text .small {
    font-size: 22.57143px;
  }
}

@media (max-width: 1365px) {
  .index-banner .swiper-container .swiper-slide .banner-text .small {
    font-size: 21.42857px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-container .swiper-slide .banner-text .small {
    font-size: 20.28571px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-container .swiper-slide .banner-text .small {
    font-size: 19.14286px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-slide .banner-text .small {
    font-size: 18px;
  }
}

.index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 1680px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-top: 37.14286px;
  }
}

@media (max-width: 1599px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-top: 34.28571px;
  }
}

@media (max-width: 1439px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-top: 31.42857px;
  }
}

@media (max-width: 1365px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-top: 28.57143px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-top: 25.71429px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-top: 22.85714px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-top: 20px;
  }
}

@media (max-width: 1680px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-bottom: 37.14286px;
  }
}

@media (max-width: 1599px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-bottom: 34.28571px;
  }
}

@media (max-width: 1439px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-bottom: 31.42857px;
  }
}

@media (max-width: 1365px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-bottom: 28.57143px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-bottom: 25.71429px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-bottom: 22.85714px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-bottom: 20px;
  }
}

@media (max-width: 1680px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-left: 18.57143px;
  }
}

@media (max-width: 1599px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-left: 17.14286px;
  }
}

@media (max-width: 1439px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-left: 15.71429px;
  }
}

@media (max-width: 1365px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-left: 14.28571px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-left: 12.85714px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-left: 11.42857px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-left: 10px;
  }
}

@media (max-width: 1680px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-right: 18.57143px;
  }
}

@media (max-width: 1599px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-right: 17.14286px;
  }
}

@media (max-width: 1439px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-right: 15.71429px;
  }
}

@media (max-width: 1365px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-right: 14.28571px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-right: 12.85714px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-right: 11.42857px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-button-next, .index-banner .swiper-container .swiper-button-prev {
    padding-right: 10px;
  }
}

.index-banner .swiper-container .swiper-button-next::after, .index-banner .swiper-container .swiper-button-prev::after {
  font-size: 30px;
}

@media (max-width: 1680px) {
  .index-banner .swiper-container .swiper-button-next::after, .index-banner .swiper-container .swiper-button-prev::after {
    font-size: 28px;
  }
}

@media (max-width: 1599px) {
  .index-banner .swiper-container .swiper-button-next::after, .index-banner .swiper-container .swiper-button-prev::after {
    font-size: 26px;
  }
}

@media (max-width: 1439px) {
  .index-banner .swiper-container .swiper-button-next::after, .index-banner .swiper-container .swiper-button-prev::after {
    font-size: 24px;
  }
}

@media (max-width: 1365px) {
  .index-banner .swiper-container .swiper-button-next::after, .index-banner .swiper-container .swiper-button-prev::after {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-container .swiper-button-next::after, .index-banner .swiper-container .swiper-button-prev::after {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-container .swiper-button-next::after, .index-banner .swiper-container .swiper-button-prev::after {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-button-next::after, .index-banner .swiper-container .swiper-button-prev::after {
    font-size: 16px;
  }
}

.index-banner .swiper-container .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
}

@media (max-width: 1680px) {
  .index-banner .swiper-container .swiper-pagination-bullet {
    width: 13.14286px;
  }
}

@media (max-width: 1599px) {
  .index-banner .swiper-container .swiper-pagination-bullet {
    width: 12.28571px;
  }
}

@media (max-width: 1439px) {
  .index-banner .swiper-container .swiper-pagination-bullet {
    width: 11.42857px;
  }
}

@media (max-width: 1365px) {
  .index-banner .swiper-container .swiper-pagination-bullet {
    width: 10.57143px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-container .swiper-pagination-bullet {
    width: 9.71429px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-container .swiper-pagination-bullet {
    width: 8.85714px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-pagination-bullet {
    width: 8px;
  }
}

@media (max-width: 1680px) {
  .index-banner .swiper-container .swiper-pagination-bullet {
    height: 13.14286px;
  }
}

@media (max-width: 1599px) {
  .index-banner .swiper-container .swiper-pagination-bullet {
    height: 12.28571px;
  }
}

@media (max-width: 1439px) {
  .index-banner .swiper-container .swiper-pagination-bullet {
    height: 11.42857px;
  }
}

@media (max-width: 1365px) {
  .index-banner .swiper-container .swiper-pagination-bullet {
    height: 10.57143px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-container .swiper-pagination-bullet {
    height: 9.71429px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-container .swiper-pagination-bullet {
    height: 8.85714px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-container .swiper-pagination-bullet {
    height: 8px;
  }
}

.index-banner .swiper-container .swiper-pagination-bullet-active {
  background: #fff;
}

.index-about {
  background: #fafafa;
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1680px) {
  .index-about {
    padding-top: 11.42857px;
  }
}

@media (max-width: 1599px) {
  .index-about {
    padding-top: 10.85714px;
  }
}

@media (max-width: 1439px) {
  .index-about {
    padding-top: 10.28571px;
  }
}

@media (max-width: 1365px) {
  .index-about {
    padding-top: 9.71429px;
  }
}

@media (max-width: 1024px) {
  .index-about {
    padding-top: 9.14286px;
  }
}

@media (max-width: 991px) {
  .index-about {
    padding-top: 8.57143px;
  }
}

@media (max-width: 767px) {
  .index-about {
    padding-top: 8px;
  }
}

@media (max-width: 1680px) {
  .index-about {
    padding-bottom: 11.42857px;
  }
}

@media (max-width: 1599px) {
  .index-about {
    padding-bottom: 10.85714px;
  }
}

@media (max-width: 1439px) {
  .index-about {
    padding-bottom: 10.28571px;
  }
}

@media (max-width: 1365px) {
  .index-about {
    padding-bottom: 9.71429px;
  }
}

@media (max-width: 1024px) {
  .index-about {
    padding-bottom: 9.14286px;
  }
}

@media (max-width: 991px) {
  .index-about {
    padding-bottom: 8.57143px;
  }
}

@media (max-width: 767px) {
  .index-about {
    padding-bottom: 8px;
  }
}

.index-about .logo {
  position: absolute;
  top: -237px;
  left: -160px;
  z-index: 9;
  width: 350px;
  height: 350px;
}

@media (max-width: 1680px) {
  .index-about .logo {
    top: -213.14286px;
  }
}

@media (max-width: 1599px) {
  .index-about .logo {
    top: -189.28571px;
  }
}

@media (max-width: 1439px) {
  .index-about .logo {
    top: -165.42857px;
  }
}

@media (max-width: 1365px) {
  .index-about .logo {
    top: -141.57143px;
  }
}

@media (max-width: 1024px) {
  .index-about .logo {
    top: -117.71429px;
  }
}

@media (max-width: 991px) {
  .index-about .logo {
    top: -93.85714px;
  }
}

@media (max-width: 767px) {
  .index-about .logo {
    top: -70px;
  }
}

@media (max-width: 1680px) {
  .index-about .logo {
    left: -144.28571px;
  }
}

@media (max-width: 1599px) {
  .index-about .logo {
    left: -128.57143px;
  }
}

@media (max-width: 1439px) {
  .index-about .logo {
    left: -112.85714px;
  }
}

@media (max-width: 1365px) {
  .index-about .logo {
    left: -97.14286px;
  }
}

@media (max-width: 1024px) {
  .index-about .logo {
    left: -81.42857px;
  }
}

@media (max-width: 991px) {
  .index-about .logo {
    left: -65.71429px;
  }
}

@media (max-width: 767px) {
  .index-about .logo {
    left: -50px;
  }
}

@media (max-width: 1680px) {
  .index-about .logo {
    width: 325.71429px;
  }
}

@media (max-width: 1599px) {
  .index-about .logo {
    width: 301.42857px;
  }
}

@media (max-width: 1439px) {
  .index-about .logo {
    width: 277.14286px;
  }
}

@media (max-width: 1365px) {
  .index-about .logo {
    width: 252.85714px;
  }
}

@media (max-width: 1024px) {
  .index-about .logo {
    width: 228.57143px;
  }
}

@media (max-width: 991px) {
  .index-about .logo {
    width: 204.28571px;
  }
}

@media (max-width: 767px) {
  .index-about .logo {
    width: 180px;
  }
}

@media (max-width: 1680px) {
  .index-about .logo {
    height: 325.71429px;
  }
}

@media (max-width: 1599px) {
  .index-about .logo {
    height: 301.42857px;
  }
}

@media (max-width: 1439px) {
  .index-about .logo {
    height: 277.14286px;
  }
}

@media (max-width: 1365px) {
  .index-about .logo {
    height: 252.85714px;
  }
}

@media (max-width: 1024px) {
  .index-about .logo {
    height: 228.57143px;
  }
}

@media (max-width: 991px) {
  .index-about .logo {
    height: 204.28571px;
  }
}

@media (max-width: 767px) {
  .index-about .logo {
    height: 180px;
  }
}

.index-about .logo img {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 auto;
}

.index-about .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .index-about .container {
    display: block;
  }
}

.index-about .container .aboutleft {
  display: flex;
  flex-flow: column;
}

.index-about .container .aboutleft .title {
  color: #333;
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
}

@media (max-width: 1680px) {
  .index-about .container .aboutleft .title {
    font-size: 28.57143px;
  }
}

@media (max-width: 1599px) {
  .index-about .container .aboutleft .title {
    font-size: 27.14286px;
  }
}

@media (max-width: 1439px) {
  .index-about .container .aboutleft .title {
    font-size: 25.71429px;
  }
}

@media (max-width: 1365px) {
  .index-about .container .aboutleft .title {
    font-size: 24.28571px;
  }
}

@media (max-width: 1024px) {
  .index-about .container .aboutleft .title {
    font-size: 22.85714px;
  }
}

@media (max-width: 991px) {
  .index-about .container .aboutleft .title {
    font-size: 21.42857px;
  }
}

@media (max-width: 767px) {
  .index-about .container .aboutleft .title {
    font-size: 20px;
  }
}

.index-about .container .aboutleft p {
  color: #333;
  font-size: 18px;
  text-align: center;
}

@media (max-width: 1680px) {
  .index-about .container .aboutleft p {
    font-size: 17.42857px;
  }
}

@media (max-width: 1599px) {
  .index-about .container .aboutleft p {
    font-size: 16.85714px;
  }
}

@media (max-width: 1439px) {
  .index-about .container .aboutleft p {
    font-size: 16.28571px;
  }
}

@media (max-width: 1365px) {
  .index-about .container .aboutleft p {
    font-size: 15.71429px;
  }
}

@media (max-width: 1024px) {
  .index-about .container .aboutleft p {
    font-size: 15.14286px;
  }
}

@media (max-width: 991px) {
  .index-about .container .aboutleft p {
    font-size: 14.57143px;
  }
}

@media (max-width: 767px) {
  .index-about .container .aboutleft p {
    font-size: 14px;
  }
}

.index-about .container .aboutright {
  flex: 1;
  font-size: 16px;
  margin-left: 200px;
  line-height: 2;
  margin-top: 0;
  text-indent: 2em;
  text-align: justify;
}

@media (max-width: 1680px) {
  .index-about .container .aboutright {
    font-size: 15.57143px;
  }
}

@media (max-width: 1599px) {
  .index-about .container .aboutright {
    font-size: 15.14286px;
  }
}

@media (max-width: 1439px) {
  .index-about .container .aboutright {
    font-size: 14.71429px;
  }
}

@media (max-width: 1365px) {
  .index-about .container .aboutright {
    font-size: 14.28571px;
  }
}

@media (max-width: 1024px) {
  .index-about .container .aboutright {
    font-size: 13.85714px;
  }
}

@media (max-width: 991px) {
  .index-about .container .aboutright {
    font-size: 13.42857px;
  }
}

@media (max-width: 767px) {
  .index-about .container .aboutright {
    font-size: 13px;
  }
}

@media (max-width: 1680px) {
  .index-about .container .aboutright {
    margin-left: 171.42857px;
  }
}

@media (max-width: 1599px) {
  .index-about .container .aboutright {
    margin-left: 142.85714px;
  }
}

@media (max-width: 1439px) {
  .index-about .container .aboutright {
    margin-left: 114.28571px;
  }
}

@media (max-width: 1365px) {
  .index-about .container .aboutright {
    margin-left: 85.71429px;
  }
}

@media (max-width: 1024px) {
  .index-about .container .aboutright {
    margin-left: 57.14286px;
  }
}

@media (max-width: 991px) {
  .index-about .container .aboutright {
    margin-left: 28.57143px;
  }
}

@media (max-width: 767px) {
  .index-about .container .aboutright {
    margin-left: 0px;
  }
}

@media (max-width: 1680px) {
  .index-about .container .aboutright {
    line-height: 1.93571;
  }
}

@media (max-width: 1599px) {
  .index-about .container .aboutright {
    line-height: 1.87143;
  }
}

@media (max-width: 1439px) {
  .index-about .container .aboutright {
    line-height: 1.80714;
  }
}

@media (max-width: 1365px) {
  .index-about .container .aboutright {
    line-height: 1.74286;
  }
}

@media (max-width: 1024px) {
  .index-about .container .aboutright {
    line-height: 1.67857;
  }
}

@media (max-width: 991px) {
  .index-about .container .aboutright {
    line-height: 1.61429;
  }
}

@media (max-width: 767px) {
  .index-about .container .aboutright {
    line-height: 1.55;
  }
}

@media (max-width: 767px) {
  .index-about .container .aboutright {
    margin-top: 10px;
  }
}

.index-pro {
  margin-top: 20px;
}

@media (max-width: 1680px) {
  .index-pro {
    margin-top: 18.57143px;
  }
}

@media (max-width: 1599px) {
  .index-pro {
    margin-top: 17.14286px;
  }
}

@media (max-width: 1439px) {
  .index-pro {
    margin-top: 15.71429px;
  }
}

@media (max-width: 1365px) {
  .index-pro {
    margin-top: 14.28571px;
  }
}

@media (max-width: 1024px) {
  .index-pro {
    margin-top: 12.85714px;
  }
}

@media (max-width: 991px) {
  .index-pro {
    margin-top: 11.42857px;
  }
}

@media (max-width: 767px) {
  .index-pro {
    margin-top: 10px;
  }
}

.index-pro .recommend {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 26px;
}

@media (max-width: 1680px) {
  .index-pro .recommend {
    margin-top: 24.42857px;
  }
}

@media (max-width: 1599px) {
  .index-pro .recommend {
    margin-top: 22.85714px;
  }
}

@media (max-width: 1439px) {
  .index-pro .recommend {
    margin-top: 21.28571px;
  }
}

@media (max-width: 1365px) {
  .index-pro .recommend {
    margin-top: 19.71429px;
  }
}

@media (max-width: 1024px) {
  .index-pro .recommend {
    margin-top: 18.14286px;
  }
}

@media (max-width: 991px) {
  .index-pro .recommend {
    margin-top: 16.57143px;
  }
}

@media (max-width: 767px) {
  .index-pro .recommend {
    margin-top: 15px;
  }
}

.index-pro .recommend ul {
  display: flex;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .index-pro .recommend ul {
    display: block;
  }
}

.index-pro .recommend ul li {
  width: 49%;
  height: 350px;
  position: relative;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .index-pro .recommend ul li {
    width: 100%;
  }
}

@media (max-width: 1680px) {
  .index-pro .recommend ul li {
    height: 328.57143px;
  }
}

@media (max-width: 1599px) {
  .index-pro .recommend ul li {
    height: 307.14286px;
  }
}

@media (max-width: 1439px) {
  .index-pro .recommend ul li {
    height: 285.71429px;
  }
}

@media (max-width: 1365px) {
  .index-pro .recommend ul li {
    height: 264.28571px;
  }
}

@media (max-width: 1024px) {
  .index-pro .recommend ul li {
    height: 242.85714px;
  }
}

@media (max-width: 991px) {
  .index-pro .recommend ul li {
    height: 221.42857px;
  }
}

@media (max-width: 767px) {
  .index-pro .recommend ul li {
    height: 200px;
  }
}

@media (max-width: 767px) {
  .index-pro .recommend ul li {
    margin-bottom: 15px;
  }
}

.index-pro .recommend ul li .img {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  -webkit-transition: background-size .3s;
  transition: background-size .3s;
}

.index-pro .recommend ul li .mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  color: #fff;
  padding-left: 90px;
  padding-right: 90px;
  transition: 0.3s;
}

@media (max-width: 1680px) {
  .index-pro .recommend ul li .mask {
    padding-left: 81.42857px;
  }
}

@media (max-width: 1599px) {
  .index-pro .recommend ul li .mask {
    padding-left: 72.85714px;
  }
}

@media (max-width: 1439px) {
  .index-pro .recommend ul li .mask {
    padding-left: 64.28571px;
  }
}

@media (max-width: 1365px) {
  .index-pro .recommend ul li .mask {
    padding-left: 55.71429px;
  }
}

@media (max-width: 1024px) {
  .index-pro .recommend ul li .mask {
    padding-left: 47.14286px;
  }
}

@media (max-width: 991px) {
  .index-pro .recommend ul li .mask {
    padding-left: 38.57143px;
  }
}

@media (max-width: 767px) {
  .index-pro .recommend ul li .mask {
    padding-left: 30px;
  }
}

@media (max-width: 1680px) {
  .index-pro .recommend ul li .mask {
    padding-right: 81.42857px;
  }
}

@media (max-width: 1599px) {
  .index-pro .recommend ul li .mask {
    padding-right: 72.85714px;
  }
}

@media (max-width: 1439px) {
  .index-pro .recommend ul li .mask {
    padding-right: 64.28571px;
  }
}

@media (max-width: 1365px) {
  .index-pro .recommend ul li .mask {
    padding-right: 55.71429px;
  }
}

@media (max-width: 1024px) {
  .index-pro .recommend ul li .mask {
    padding-right: 47.14286px;
  }
}

@media (max-width: 991px) {
  .index-pro .recommend ul li .mask {
    padding-right: 38.57143px;
  }
}

@media (max-width: 767px) {
  .index-pro .recommend ul li .mask {
    padding-right: 30px;
  }
}

.index-pro .recommend ul li .mask .title {
  font-size: 24px;
  font-weight: bolder;
  position: relative;
}

@media (max-width: 1680px) {
  .index-pro .recommend ul li .mask .title {
    font-size: 23.42857px;
  }
}

@media (max-width: 1599px) {
  .index-pro .recommend ul li .mask .title {
    font-size: 22.85714px;
  }
}

@media (max-width: 1439px) {
  .index-pro .recommend ul li .mask .title {
    font-size: 22.28571px;
  }
}

@media (max-width: 1365px) {
  .index-pro .recommend ul li .mask .title {
    font-size: 21.71429px;
  }
}

@media (max-width: 1024px) {
  .index-pro .recommend ul li .mask .title {
    font-size: 21.14286px;
  }
}

@media (max-width: 991px) {
  .index-pro .recommend ul li .mask .title {
    font-size: 20.57143px;
  }
}

@media (max-width: 767px) {
  .index-pro .recommend ul li .mask .title {
    font-size: 20px;
  }
}

.index-pro .recommend ul li .mask .line {
  width: 80px;
  height: 2px;
  background: #fff;
}

.index-pro .recommend ul li .mask .desc {
  font-size: 16px;
  margin-top: 20px;
  line-height: 1.8;
  text-align: center;
  display: none;
}

@media (max-width: 1680px) {
  .index-pro .recommend ul li .mask .desc {
    font-size: 15.71429px;
  }
}

@media (max-width: 1599px) {
  .index-pro .recommend ul li .mask .desc {
    font-size: 15.42857px;
  }
}

@media (max-width: 1439px) {
  .index-pro .recommend ul li .mask .desc {
    font-size: 15.14286px;
  }
}

@media (max-width: 1365px) {
  .index-pro .recommend ul li .mask .desc {
    font-size: 14.85714px;
  }
}

@media (max-width: 1024px) {
  .index-pro .recommend ul li .mask .desc {
    font-size: 14.57143px;
  }
}

@media (max-width: 991px) {
  .index-pro .recommend ul li .mask .desc {
    font-size: 14.28571px;
  }
}

@media (max-width: 767px) {
  .index-pro .recommend ul li .mask .desc {
    font-size: 14px;
  }
}

@media (max-width: 1680px) {
  .index-pro .recommend ul li .mask .desc {
    margin-top: 19.14286px;
  }
}

@media (max-width: 1599px) {
  .index-pro .recommend ul li .mask .desc {
    margin-top: 18.28571px;
  }
}

@media (max-width: 1439px) {
  .index-pro .recommend ul li .mask .desc {
    margin-top: 17.42857px;
  }
}

@media (max-width: 1365px) {
  .index-pro .recommend ul li .mask .desc {
    margin-top: 16.57143px;
  }
}

@media (max-width: 1024px) {
  .index-pro .recommend ul li .mask .desc {
    margin-top: 15.71429px;
  }
}

@media (max-width: 991px) {
  .index-pro .recommend ul li .mask .desc {
    margin-top: 14.85714px;
  }
}

@media (max-width: 767px) {
  .index-pro .recommend ul li .mask .desc {
    margin-top: 14px;
  }
}

@media (max-width: 1680px) {
  .index-pro .recommend ul li .mask .desc {
    line-height: 1.77143;
  }
}

@media (max-width: 1599px) {
  .index-pro .recommend ul li .mask .desc {
    line-height: 1.74286;
  }
}

@media (max-width: 1439px) {
  .index-pro .recommend ul li .mask .desc {
    line-height: 1.71429;
  }
}

@media (max-width: 1365px) {
  .index-pro .recommend ul li .mask .desc {
    line-height: 1.68571;
  }
}

@media (max-width: 1024px) {
  .index-pro .recommend ul li .mask .desc {
    line-height: 1.65714;
  }
}

@media (max-width: 991px) {
  .index-pro .recommend ul li .mask .desc {
    line-height: 1.62857;
  }
}

@media (max-width: 767px) {
  .index-pro .recommend ul li .mask .desc {
    line-height: 1.6;
  }
}

.index-pro .recommend ul li:hover .img {
  background-size: 120%;
}

.index-pro .recommend ul li:hover .mask {
  background: rgba(0, 0, 0, 0.7);
}

.index-pro .prolist {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .index-pro .prolist {
    margin-top: 0;
  }
}

.index-pro .prolist ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.index-pro .prolist ul li {
  width: 19%;
  margin-bottom: 0;
  box-shadow: 0 0 10px #ccc;
}

@media (max-width: 1024px) {
  .index-pro .prolist ul li {
    width: 49%;
  }
}

@media (max-width: 767px) {
  .index-pro .prolist ul li {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .index-pro .prolist ul li {
    margin-bottom: 15px;
  }
}

.index-pro .prolist ul li a .img {
  width: 100%;
  height: 249px;
  overflow: hidden;
}

@media (max-width: 1680px) {
  .index-pro .prolist ul li a .img {
    height: 239.14286px;
  }
}

@media (max-width: 1599px) {
  .index-pro .prolist ul li a .img {
    height: 229.28571px;
  }
}

@media (max-width: 1439px) {
  .index-pro .prolist ul li a .img {
    height: 219.42857px;
  }
}

@media (max-width: 1365px) {
  .index-pro .prolist ul li a .img {
    height: 209.57143px;
  }
}

@media (max-width: 1024px) {
  .index-pro .prolist ul li a .img {
    height: 199.71429px;
  }
}

@media (max-width: 991px) {
  .index-pro .prolist ul li a .img {
    height: 189.85714px;
  }
}

@media (max-width: 767px) {
  .index-pro .prolist ul li a .img {
    height: 180px;
  }
}

.index-pro .prolist ul li a .img img {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
  transition: 0.3s;
}

.index-pro .prolist ul li a .con {
  width: 100%;
  min-height: 180px;
  padding: 20px 20px 0 20px;
}

@media (max-width: 1680px) {
  .index-pro .prolist ul li a .con {
    min-height: 174.28571px;
  }
}

@media (max-width: 1599px) {
  .index-pro .prolist ul li a .con {
    min-height: 168.57143px;
  }
}

@media (max-width: 1439px) {
  .index-pro .prolist ul li a .con {
    min-height: 162.85714px;
  }
}

@media (max-width: 1365px) {
  .index-pro .prolist ul li a .con {
    min-height: 157.14286px;
  }
}

@media (max-width: 1024px) {
  .index-pro .prolist ul li a .con {
    min-height: 151.42857px;
  }
}

@media (max-width: 991px) {
  .index-pro .prolist ul li a .con {
    min-height: 145.71429px;
  }
}

@media (max-width: 767px) {
  .index-pro .prolist ul li a .con {
    min-height: 140px;
  }
}

.index-pro .prolist ul li a .con .title {
  font-size: 22px;
  font-weight: bolder;
  transition: 0.3s;
}

@media (max-width: 1680px) {
  .index-pro .prolist ul li a .con .title {
    font-size: 21.42857px;
  }
}

@media (max-width: 1599px) {
  .index-pro .prolist ul li a .con .title {
    font-size: 20.85714px;
  }
}

@media (max-width: 1439px) {
  .index-pro .prolist ul li a .con .title {
    font-size: 20.28571px;
  }
}

@media (max-width: 1365px) {
  .index-pro .prolist ul li a .con .title {
    font-size: 19.71429px;
  }
}

@media (max-width: 1024px) {
  .index-pro .prolist ul li a .con .title {
    font-size: 19.14286px;
  }
}

@media (max-width: 991px) {
  .index-pro .prolist ul li a .con .title {
    font-size: 18.57143px;
  }
}

@media (max-width: 767px) {
  .index-pro .prolist ul li a .con .title {
    font-size: 18px;
  }
}

.index-pro .prolist ul li a .con .desc {
  color: #333;
  font-size: 14px;
  margin-top: 20px;
  transition: 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (max-width: 1680px) {
  .index-pro .prolist ul li a .con .desc {
    font-size: 13.85714px;
  }
}

@media (max-width: 1599px) {
  .index-pro .prolist ul li a .con .desc {
    font-size: 13.71429px;
  }
}

@media (max-width: 1439px) {
  .index-pro .prolist ul li a .con .desc {
    font-size: 13.57143px;
  }
}

@media (max-width: 1365px) {
  .index-pro .prolist ul li a .con .desc {
    font-size: 13.42857px;
  }
}

@media (max-width: 1024px) {
  .index-pro .prolist ul li a .con .desc {
    font-size: 13.28571px;
  }
}

@media (max-width: 991px) {
  .index-pro .prolist ul li a .con .desc {
    font-size: 13.14286px;
  }
}

@media (max-width: 767px) {
  .index-pro .prolist ul li a .con .desc {
    font-size: 13px;
  }
}

@media (max-width: 1680px) {
  .index-pro .prolist ul li a .con .desc {
    margin-top: 19.28571px;
  }
}

@media (max-width: 1599px) {
  .index-pro .prolist ul li a .con .desc {
    margin-top: 18.57143px;
  }
}

@media (max-width: 1439px) {
  .index-pro .prolist ul li a .con .desc {
    margin-top: 17.85714px;
  }
}

@media (max-width: 1365px) {
  .index-pro .prolist ul li a .con .desc {
    margin-top: 17.14286px;
  }
}

@media (max-width: 1024px) {
  .index-pro .prolist ul li a .con .desc {
    margin-top: 16.42857px;
  }
}

@media (max-width: 991px) {
  .index-pro .prolist ul li a .con .desc {
    margin-top: 15.71429px;
  }
}

@media (max-width: 767px) {
  .index-pro .prolist ul li a .con .desc {
    margin-top: 15px;
  }
}

.index-pro .prolist ul li:hover .img img {
  transform: scale(1.2);
}

.index-pro .prolist ul li:hover .con .title {
  margin-left: 10px;
  color: #00a2dc;
}

.index-pro .prolist ul li:hover .con .desc {
  color: #00a2dc;
}

.index-pro .pro-more {
  width: 275px;
  height: 40px;
  border-radius: 4px;
  background: #438ac7;
  margin: 0 auto;
  margin-top: 50px;
  transition: 0.3s;
}

@media (max-width: 1680px) {
  .index-pro .pro-more {
    width: 261.42857px;
  }
}

@media (max-width: 1599px) {
  .index-pro .pro-more {
    width: 247.85714px;
  }
}

@media (max-width: 1439px) {
  .index-pro .pro-more {
    width: 234.28571px;
  }
}

@media (max-width: 1365px) {
  .index-pro .pro-more {
    width: 220.71429px;
  }
}

@media (max-width: 1024px) {
  .index-pro .pro-more {
    width: 207.14286px;
  }
}

@media (max-width: 991px) {
  .index-pro .pro-more {
    width: 193.57143px;
  }
}

@media (max-width: 767px) {
  .index-pro .pro-more {
    width: 180px;
  }
}

@media (max-width: 1680px) {
  .index-pro .pro-more {
    margin-top: 45.71429px;
  }
}

@media (max-width: 1599px) {
  .index-pro .pro-more {
    margin-top: 41.42857px;
  }
}

@media (max-width: 1439px) {
  .index-pro .pro-more {
    margin-top: 37.14286px;
  }
}

@media (max-width: 1365px) {
  .index-pro .pro-more {
    margin-top: 32.85714px;
  }
}

@media (max-width: 1024px) {
  .index-pro .pro-more {
    margin-top: 28.57143px;
  }
}

@media (max-width: 991px) {
  .index-pro .pro-more {
    margin-top: 24.28571px;
  }
}

@media (max-width: 767px) {
  .index-pro .pro-more {
    margin-top: 20px;
  }
}

.index-pro .pro-more a {
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.index-pro .pro-more:hover {
  box-shadow: -5px 5px 3px 0px #3878af;
}

.index-society {
  background: #fafafa;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 70px;
}

@media (max-width: 1680px) {
  .index-society {
    padding-top: 19.28571px;
  }
}

@media (max-width: 1599px) {
  .index-society {
    padding-top: 18.57143px;
  }
}

@media (max-width: 1439px) {
  .index-society {
    padding-top: 17.85714px;
  }
}

@media (max-width: 1365px) {
  .index-society {
    padding-top: 17.14286px;
  }
}

@media (max-width: 1024px) {
  .index-society {
    padding-top: 16.42857px;
  }
}

@media (max-width: 991px) {
  .index-society {
    padding-top: 15.71429px;
  }
}

@media (max-width: 767px) {
  .index-society {
    padding-top: 15px;
  }
}

@media (max-width: 1680px) {
  .index-society {
    padding-bottom: 19.28571px;
  }
}

@media (max-width: 1599px) {
  .index-society {
    padding-bottom: 18.57143px;
  }
}

@media (max-width: 1439px) {
  .index-society {
    padding-bottom: 17.85714px;
  }
}

@media (max-width: 1365px) {
  .index-society {
    padding-bottom: 17.14286px;
  }
}

@media (max-width: 1024px) {
  .index-society {
    padding-bottom: 16.42857px;
  }
}

@media (max-width: 991px) {
  .index-society {
    padding-bottom: 15.71429px;
  }
}

@media (max-width: 767px) {
  .index-society {
    padding-bottom: 15px;
  }
}

@media (max-width: 1680px) {
  .index-society {
    margin-top: 64.28571px;
  }
}

@media (max-width: 1599px) {
  .index-society {
    margin-top: 58.57143px;
  }
}

@media (max-width: 1439px) {
  .index-society {
    margin-top: 52.85714px;
  }
}

@media (max-width: 1365px) {
  .index-society {
    margin-top: 47.14286px;
  }
}

@media (max-width: 1024px) {
  .index-society {
    margin-top: 41.42857px;
  }
}

@media (max-width: 991px) {
  .index-society {
    margin-top: 35.71429px;
  }
}

@media (max-width: 767px) {
  .index-society {
    margin-top: 30px;
  }
}

.index-society .list {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: 26px;
}

@media (max-width: 1680px) {
  .index-society .list {
    margin-top: 24.42857px;
  }
}

@media (max-width: 1599px) {
  .index-society .list {
    margin-top: 22.85714px;
  }
}

@media (max-width: 1439px) {
  .index-society .list {
    margin-top: 21.28571px;
  }
}

@media (max-width: 1365px) {
  .index-society .list {
    margin-top: 19.71429px;
  }
}

@media (max-width: 1024px) {
  .index-society .list {
    margin-top: 18.14286px;
  }
}

@media (max-width: 991px) {
  .index-society .list {
    margin-top: 16.57143px;
  }
}

@media (max-width: 767px) {
  .index-society .list {
    margin-top: 15px;
  }
}

.index-society .list ul {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .index-society .list ul {
    display: block;
  }
}

.index-society .list ul li {
  width: 33%;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .index-society .list ul li {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index-society .list ul li {
    margin-bottom: 15px;
  }
}

.index-society .list ul li .img {
  width: 100%;
  height: 360px;
  overflow: hidden;
}

@media (max-width: 1680px) {
  .index-society .list ul li .img {
    height: 337.14286px;
  }
}

@media (max-width: 1599px) {
  .index-society .list ul li .img {
    height: 314.28571px;
  }
}

@media (max-width: 1439px) {
  .index-society .list ul li .img {
    height: 291.42857px;
  }
}

@media (max-width: 1365px) {
  .index-society .list ul li .img {
    height: 268.57143px;
  }
}

@media (max-width: 1024px) {
  .index-society .list ul li .img {
    height: 245.71429px;
  }
}

@media (max-width: 991px) {
  .index-society .list ul li .img {
    height: 222.85714px;
  }
}

@media (max-width: 767px) {
  .index-society .list ul li .img {
    height: 200px;
  }
}

.index-society .list ul li .img img {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
  transition: 0.3s;
}

.index-society .list ul li h1 {
  font-size: 28px;
  margin-top: 20px;
  text-align: center;
  color: #666;
  position: relative;
  transition: 0.3s;
}

@media (max-width: 1680px) {
  .index-society .list ul li h1 {
    font-size: 26.85714px;
  }
}

@media (max-width: 1599px) {
  .index-society .list ul li h1 {
    font-size: 25.71429px;
  }
}

@media (max-width: 1439px) {
  .index-society .list ul li h1 {
    font-size: 24.57143px;
  }
}

@media (max-width: 1365px) {
  .index-society .list ul li h1 {
    font-size: 23.42857px;
  }
}

@media (max-width: 1024px) {
  .index-society .list ul li h1 {
    font-size: 22.28571px;
  }
}

@media (max-width: 991px) {
  .index-society .list ul li h1 {
    font-size: 21.14286px;
  }
}

@media (max-width: 767px) {
  .index-society .list ul li h1 {
    font-size: 20px;
  }
}

@media (max-width: 1680px) {
  .index-society .list ul li h1 {
    margin-top: 19.28571px;
  }
}

@media (max-width: 1599px) {
  .index-society .list ul li h1 {
    margin-top: 18.57143px;
  }
}

@media (max-width: 1439px) {
  .index-society .list ul li h1 {
    margin-top: 17.85714px;
  }
}

@media (max-width: 1365px) {
  .index-society .list ul li h1 {
    margin-top: 17.14286px;
  }
}

@media (max-width: 1024px) {
  .index-society .list ul li h1 {
    margin-top: 16.42857px;
  }
}

@media (max-width: 991px) {
  .index-society .list ul li h1 {
    margin-top: 15.71429px;
  }
}

@media (max-width: 767px) {
  .index-society .list ul li h1 {
    margin-top: 15px;
  }
}

.index-society .list ul li:hover .img img {
  transform: scale(1.2);
}

.index-society .list ul li:hover h1 {
  color: #00a2dc;
}

.index-news {
  margin-top: 120px;
}

@media (max-width: 1680px) {
  .index-news {
    margin-top: 107.14286px;
  }
}

@media (max-width: 1599px) {
  .index-news {
    margin-top: 94.28571px;
  }
}

@media (max-width: 1439px) {
  .index-news {
    margin-top: 81.42857px;
  }
}

@media (max-width: 1365px) {
  .index-news {
    margin-top: 68.57143px;
  }
}

@media (max-width: 1024px) {
  .index-news {
    margin-top: 55.71429px;
  }
}

@media (max-width: 991px) {
  .index-news {
    margin-top: 42.85714px;
  }
}

@media (max-width: 767px) {
  .index-news {
    margin-top: 30px;
  }
}

.index-news .container {
  box-sizing: content-box;
}

@media (max-width: 1680px) {
  .index-news .container {
    box-sizing: border-box;
  }
}

.index-news .news {
  margin-top: 26px;
}

@media (max-width: 1680px) {
  .index-news .news {
    margin-top: 24.42857px;
  }
}

@media (max-width: 1599px) {
  .index-news .news {
    margin-top: 22.85714px;
  }
}

@media (max-width: 1439px) {
  .index-news .news {
    margin-top: 21.28571px;
  }
}

@media (max-width: 1365px) {
  .index-news .news {
    margin-top: 19.71429px;
  }
}

@media (max-width: 1024px) {
  .index-news .news {
    margin-top: 18.14286px;
  }
}

@media (max-width: 991px) {
  .index-news .news {
    margin-top: 16.57143px;
  }
}

@media (max-width: 767px) {
  .index-news .news {
    margin-top: 15px;
  }
}

.index-news .news ul li {
  width: 100%;
  height: 690px;
}

@media (max-width: 1680px) {
  .index-news .news ul li {
    height: 624.28571px;
  }
}

@media (max-width: 1599px) {
  .index-news .news ul li {
    height: 558.57143px;
  }
}

@media (max-width: 1439px) {
  .index-news .news ul li {
    height: 492.85714px;
  }
}

@media (max-width: 1365px) {
  .index-news .news ul li {
    height: 427.14286px;
  }
}

@media (max-width: 1024px) {
  .index-news .news ul li {
    height: 361.42857px;
  }
}

@media (max-width: 991px) {
  .index-news .news ul li {
    height: 295.71429px;
  }
}

@media (max-width: 767px) {
  .index-news .news ul li {
    height: 230px;
  }
}

.index-news .news ul li a {
  width: 100%;
  height: 100%;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  z-index: 0;
}

.index-news .swiper-button-next, .index-news .swiper-button-prev {
  width: 45px;
  height: 45px;
  color: #b59ec9;
  border: 3px solid #b59ec9;
  border-radius: 50%;
}

@media (max-width: 1680px) {
  .index-news .swiper-button-next, .index-news .swiper-button-prev {
    width: 42.85714px;
  }
}

@media (max-width: 1599px) {
  .index-news .swiper-button-next, .index-news .swiper-button-prev {
    width: 40.71429px;
  }
}

@media (max-width: 1439px) {
  .index-news .swiper-button-next, .index-news .swiper-button-prev {
    width: 38.57143px;
  }
}

@media (max-width: 1365px) {
  .index-news .swiper-button-next, .index-news .swiper-button-prev {
    width: 36.42857px;
  }
}

@media (max-width: 1024px) {
  .index-news .swiper-button-next, .index-news .swiper-button-prev {
    width: 34.28571px;
  }
}

@media (max-width: 991px) {
  .index-news .swiper-button-next, .index-news .swiper-button-prev {
    width: 32.14286px;
  }
}

@media (max-width: 767px) {
  .index-news .swiper-button-next, .index-news .swiper-button-prev {
    width: 30px;
  }
}

@media (max-width: 1680px) {
  .index-news .swiper-button-next, .index-news .swiper-button-prev {
    height: 42.85714px;
  }
}

@media (max-width: 1599px) {
  .index-news .swiper-button-next, .index-news .swiper-button-prev {
    height: 40.71429px;
  }
}

@media (max-width: 1439px) {
  .index-news .swiper-button-next, .index-news .swiper-button-prev {
    height: 38.57143px;
  }
}

@media (max-width: 1365px) {
  .index-news .swiper-button-next, .index-news .swiper-button-prev {
    height: 36.42857px;
  }
}

@media (max-width: 1024px) {
  .index-news .swiper-button-next, .index-news .swiper-button-prev {
    height: 34.28571px;
  }
}

@media (max-width: 991px) {
  .index-news .swiper-button-next, .index-news .swiper-button-prev {
    height: 32.14286px;
  }
}

@media (max-width: 767px) {
  .index-news .swiper-button-next, .index-news .swiper-button-prev {
    height: 30px;
  }
}

.index-news .swiper-button-next:after, .index-news .swiper-button-prev:after {
  font-size: 16px;
  font-size: 16px;
  font-weight: bolder;
}

@media (max-width: 1680px) {
  .index-news .swiper-button-next:after, .index-news .swiper-button-prev:after {
    font-size: 15.42857px;
  }
}

@media (max-width: 1599px) {
  .index-news .swiper-button-next:after, .index-news .swiper-button-prev:after {
    font-size: 14.85714px;
  }
}

@media (max-width: 1439px) {
  .index-news .swiper-button-next:after, .index-news .swiper-button-prev:after {
    font-size: 14.28571px;
  }
}

@media (max-width: 1365px) {
  .index-news .swiper-button-next:after, .index-news .swiper-button-prev:after {
    font-size: 13.71429px;
  }
}

@media (max-width: 1024px) {
  .index-news .swiper-button-next:after, .index-news .swiper-button-prev:after {
    font-size: 13.14286px;
  }
}

@media (max-width: 991px) {
  .index-news .swiper-button-next:after, .index-news .swiper-button-prev:after {
    font-size: 12.57143px;
  }
}

@media (max-width: 767px) {
  .index-news .swiper-button-next:after, .index-news .swiper-button-prev:after {
    font-size: 12px;
  }
}

.index-news .moduleTitle h1 {
  position: relative;
}

.index-news .moduleTitle h1 .news-more {
  position: absolute;
  top: 20%;
  right: 0;
  font-size: 22px;
  font-weight: normal;
}

@media (max-width: 1680px) {
  .index-news .moduleTitle h1 .news-more {
    font-size: 21.14286px;
  }
}

@media (max-width: 1599px) {
  .index-news .moduleTitle h1 .news-more {
    font-size: 20.28571px;
  }
}

@media (max-width: 1439px) {
  .index-news .moduleTitle h1 .news-more {
    font-size: 19.42857px;
  }
}

@media (max-width: 1365px) {
  .index-news .moduleTitle h1 .news-more {
    font-size: 18.57143px;
  }
}

@media (max-width: 1024px) {
  .index-news .moduleTitle h1 .news-more {
    font-size: 17.71429px;
  }
}

@media (max-width: 991px) {
  .index-news .moduleTitle h1 .news-more {
    font-size: 16.85714px;
  }
}

@media (max-width: 767px) {
  .index-news .moduleTitle h1 .news-more {
    font-size: 16px;
  }
}

.index-news .moduleTitle h1 .news-more a {
  transition: 0.3s;
}

.index-news .moduleTitle h1 .news-more a:hover {
  color: #00a2dc;
}
