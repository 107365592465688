@import "../../assets/styles/utils";
.index-banner{
    @include res(height,100vh,(md:600px,sm:500px,xs:400px));
    .swiper-container{
        height: 100%;
        .swiper-slide{
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            z-index: 0;
            &.swiper-slide-active{
                z-index: 1;
                .banner-text{
                    span,.line,.small{
                        top: 0px;
                        opacity: 1;
                        transition-delay: 1s;
                    }
                }
            }
            .slide-inner{
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                overflow: hidden;
                b{
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 0;
                  display: block;
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  &::after{
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    // background-color: rgba(0,0,0,.3);
                    position: absolute;
                    top: 0;
                    left: 0;
                    pointer-events: none;
                  }
                  &.pc-img{
                    @include res(display,block,(sm:none));
                  }
                  &.mob-img{
                    @include res(display,none,(sm:block));
                  }
                }
                .video{
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 1;
                  &::after{
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    // background-color: rgba(0,0,0,.3);
                    position: absolute;
                    top: 0;
                    left: 0;
                    pointer-events: none;
                  }
                  video{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                  .video-play{
                    position: absolute;
                    z-index: 3;
                    left: 50%;
                    transform: translate3d(-50%,-50%,0);
                    cursor: pointer;
                    @include res(top,50%,(xs:58%));
                    @include res(display,none,(md:block));
                    z-index: 101;
                    img{
                      display: inline-block;
                      width: 60px;
                    }
                  }
                }
                .video-play{
                  position: absolute;
                  z-index: 3;
                  left: 50%;
                  transform: translate3d(-50%,-50%,0);
                  cursor: pointer;
                  @include res(top,50%,(xs:58%));
                  @include res(display,none,(md:block));
                  z-index: 101;
                  img{
                    display: inline-block;
                    width: 60px;
                  }
                }
            }
            .banner-text{
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.2);
                color: #fff;
                display: flex;
                flex-flow: column;
                align-items: flex-start;
                justify-content: center;
                @include res(padding-right,330px, 60 / 330);
                @include res(padding-left,330px, 60 / 330);
                z-index: 99;
                span{
                    font-weight: bold;
                    position: relative;
                    top: 50px;
                    opacity: 0;
                    transition: all .8s;
                    transition-delay: 0s;
                    @include res(font-size, 46px, 22 / 46);
                }
                .line{
                    width:270px;
                    height: 2px;
                    background:#fff;
                    margin-top: 20px;
                    position: relative;
                    top: 60px;
                    opacity: 0;
                    transition: all .8s;
                    transition-delay: 0s;
                }
                .small{
                    @include res(font-size, 26px, 18 / 26);
                    margin-top: 20px;
                    position: relative;
                    top: 70px;
                    opacity: 0;
                    transition: all .8s;
                    transition-delay: 0s;
                }
            }
        }
        .swiper-button-next, .swiper-button-prev{
          color: #fff;
          background: rgba(0,0,0,.4);
          @include res(padding-top, 40px, 20 / 40);
          @include res(padding-bottom, 40px, 20 / 40);
          @include res(padding-left, 20px, 10 / 20);
          @include res(padding-right, 20px, 10 / 20);
          &::after{
            @include res(font-size, 30px, 16 / 30);
          }
        }
        .swiper-pagination-bullet{
            @include res(width, 14px, 8 / 14);
            @include res(height, 14px, 8 / 14);
        }
        .swiper-pagination-bullet-active{
            background: #fff;
        }
    }
}
.index-about{
    background: #fafafa;
    @include res(padding-top,12px, 8 / 12);
    @include res(padding-bottom,12px, 8 / 12);
    position: relative;
    overflow: hidden;
    .logo{
        position: absolute;
        @include res(top,-237px, -70 / -237);
        @include res(left,-160px, -50 / -160);
        z-index:9;
        @include res(width,350px, 180 / 350);
        @include res(height,350px, 180 / 350);
        img{
            width:100%;
            height: 100%;
            display: flex;
            margin: 0 auto;
        }
    }
    .container{
        @include res(display,flex, (xs:block));
        align-items: center;
        justify-content: space-between;
        .aboutleft{
            display: flex;
            flex-flow: column;
            .title{
              color: #333;
              @include res(font-size,30px, 20 / 30);
              font-weight: bolder;
              text-align: center;
            }
            p{
              color:$color-text;
              @include res(font-size,18px, 14 / 18);
              text-align: center;
            }
        }
        .aboutright{
            flex: 1;
            @include res(font-size,16px, 13 / 16);
            @include res(margin-left,200px, 0 / 200);
            @include res(line-height,2, 1.55 / 2);
            @include res(margin-top,0, (xs:10px));
            text-indent: 2em;
            text-align: justify;
        }
    }
}
.index-pro{
    @include res(margin-top, 20px, 10 / 20);
    .recommend{
        width: 100%;
        height: auto;
        overflow: hidden;
        @include res(margin-top, 26px, 15 / 26);
        ul{
            display: flex;
            justify-content: space-between;
            @include res(display, flex, (xs:block));
            li{
                @include res(width, 49%, (xs:100%));
                @include res(height, 350px, 200 / 350);
                position: relative;
                @include res(margin-bottom, 0, (xs:15px));
                .img{
                    width: 100%;
                    height: 100%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    -webkit-transition: background-size .3s;
                    transition: background-size .3s;
                }
                .mask{
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.3);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 9;
                    display: flex;
                    align-items: center;
                    flex-flow: column;
                    justify-content: center;
                    color: #fff;
                    @include res(padding-left, 90px, 30 / 90);
                    @include res(padding-right, 90px, 30 / 90);
                    transition: 0.3s;
                    .title{
                        @include res(font-size, 24px, 20 / 24);
                        font-weight: bolder;
                        position: relative;
                    }
                    .line{
                        width:80px;
                        height: 2px;
                        background: #fff;
                    }
                    .desc{
                        @include res(font-size, 16px, 14 / 16);
                        @include res(margin-top, 20px, 14 / 20);
                        @include res(line-height, 1.8, 1.6 / 1.8);
                        text-align: center;
                        display: none;
                        // transition: 0.3s;
                    }
                }
                &:hover{
                  .mask{
                    // .desc{
                    //   display: block;
                    // }
                  }
                  .img{
                    background-size: 120%;
                  }
                  .mask{
                    background: rgba(0,0,0,.7);
                  }
                }
            }
        }
    }
    .prolist{
        @include res(margin-top, 20px, (xs:0));
        ul{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li{
                @include res(width, 19%, (md:49%,xs:100%));
                @include res(margin-bottom, 0, (md:15px));
                box-shadow: 0 0 10px #ccc;
                a{
                    .img{
                        width: 100%;
                        @include res(height, 249px, 180 / 249);
                        overflow: hidden;
                        img{
                          width: 100%;
                          height: 100%;
                          display: block;
                          margin:0 auto;
                          object-fit: cover;
                          transition: 0.3s;
                        }
                    }
                    .con{
                        width: 100%;
                        @include res(min-height, 180px, 140 / 180);
                        padding: 20px 20px 0 20px;
                        .title{
                            @include res(font-size, 22px, 18 / 22);
                            font-weight:bolder;
                            transition: 0.3s;
                        }
                        .desc{
                            color: $color-text;
                            @include res(font-size, 14px, 13 / 14);
                            @include res(margin-top, 20px, 15 / 20);
                            transition: 0.3s;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                &:hover{
                  .img{
                    img{
                      transform: scale(1.2);
                    }
                  }
                  .con{
                    .title{
                      margin-left: 10px;
                      color:#00a2dc;
                    }
                    .desc{
                      color:#00a2dc;
                    }
                  }
                }
            }
        }
    }
    .pro-more{
      @include res(width, 275px, 180 / 275);
      height:40px;
      border-radius: 4px;
      background: #438ac7;
      margin: 0 auto;
      @include res(margin-top, 50px, 20 / 50);
      transition: 0.3s;
      a{
        width: 100%;
        height: 100%;
        color: #fff;
        font-size:16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover{
        box-shadow:-5px 5px 3px 0px #3878af;
      }
    }
}
.index-society{
    background:#fafafa;
    @include res(padding-top,20px, 15 / 20);
    @include res(padding-bottom,20px, 15 / 20);
    @include res(margin-top,70px, 30 / 70);
    .list{
        width: 100%;
        height: auto;
        overflow: hidden;
        @include res(margin-top, 26px, 15 / 26);
        ul{
            @include res(display,flex, (xs:block));
            justify-content: space-between;
            li{
                @include res(width,33%, (xs:100%));
                @include res(margin-bottom,0, (xs:15px));
                .img{
                    width: 100%;
                    @include res(height, 360px, 200 / 360);
                    overflow: hidden;
                    img{
                      width: 100%;
                      height: 100%;
                      display: block;
                      margin:0 auto;
                      object-fit: cover;
                      transition: 0.3s;
                    }
                }
                h1{
                    @include res(font-size, 28px, 20 / 28);
                    @include res(margin-top, 20px, 15 / 20);
                    text-align: center;
                    color: #666;
                    position: relative;
                    transition: 0.3s;
                }
                &:hover{
                  .img{
                    img{
                      transform: scale(1.2);
                    }
                  }
                  h1{
                    color: #00a2dc;
                  }
                }
            }
        }
    }
}
.index-news{
  @include res(margin-top,120px, 30 / 120);
  .container{
    @include res(box-sizing,content-box,(1lg:border-box));
  }
  .news{
    @include res(margin-top, 26px, 15 / 26);
    ul{
      li{
        width: 100%;
        @include res(height,690px, 230 / 690);
        a{
          width: 100%;
          height: 100%;
          display: block;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          overflow: hidden;
          z-index: 0;
        }
      }
    }
  }
  .swiper-button-next, .swiper-button-prev{
    @include res(width,45px, 30 / 45);
    @include res(height,45px, 30 / 45);
    color: #b59ec9;
    border: 3px solid #b59ec9;
    border-radius: 50%;
  }
  .swiper-button-next:after, .swiper-button-prev:after{
    font-size: 16px;
    @include res(font-size,16px, 12 / 16);
    font-weight: bolder;
  }
  .moduleTitle{
    h1{
      position: relative;
      .news-more{
        position: absolute;
        top: 20%;
        right: 0;
        @include res(font-size,22px, 16 / 22);
        font-weight: normal;
        a{
          transition: 0.3s;
          &:hover{
            color: #00a2dc;
          }
        }
      }
    }
  }
}
